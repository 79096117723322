import cx from 'classnames'
import { CoverBackground } from 'components/CoverBackground'
import Link from 'next/link'
import { ComponentType, HTMLAttributes } from 'react'
import { GoChevronRight } from 'react-icons/go'
import { Col, Row } from 'reactstrap'

export const FeaturedPostCard: ComponentType<
  {
    post: any
    compact?: boolean
  } & HTMLAttributes<HTMLDivElement>
> = ({ post, className, compact = false, ...props }) => (
  <div className={cx('rounded-lg bg-white mega-shadow', className)} {...props}>
    <Row>
      <Col lg={compact ? 12 : 6} className="d-flex flex-column">
        <CoverBackground
          url={post.feature_image}
          className={cx('bg-blue-zodiac px-25 pb-40 pt-100 d-flex flex-column justify-content-end flex-fill', {
            'rounded-lg-left': !compact,
            'rounded-lg': compact,
          })}
        >
          <div className="rounded bg-white d-inline-block w-50px h-50px d-flex align-items-center justify-content-center">
            <img alt="Inicio" src="/images/yp-brand-icon.svg" width="26px" height="30px" />
          </div>
          <h6 className="text-white text-shadow font-size-14 mt-12">Blog Descubre Yucatán</h6>
          <Link
            href={{
              pathname: '/blog/[postSlug]',
              query: { postSlug: post.slug },
            }}
            as={`/blog/${post.slug}`}
          >
            <a title={post.title}>
              <h4 className="mb-0 text-white text-shadow mt-16">{post.title}</h4>
            </a>
          </Link>
        </CoverBackground>
      </Col>
      {!compact && (
        <Col lg={6} className="d-flex flex-column justify-content-center">
          <div className="pr-30 py-40">
            <p className="line-height-md">{post.excerpt}</p>
            <Link
              href={{
                pathname: '/blog/[postSlug]',
                query: { postSlug: post.slug },
              }}
              as={`/blog/${post.slug}`}
            >
              <a title="Ver artículo completo" className="font-weight-500">
                Ver artículo completo <GoChevronRight />
              </a>
            </Link>
          </div>
        </Col>
      )}
    </Row>
  </div>
)
