import { Media } from 'media'
import { ComponentType } from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { HeroDesktop } from './hero-desktop'
import { HeroMobile } from './hero-mobile'

const Wrapper = styled.div`
  padding-bottom: 56px;

  ${up('sm')} {
    padding-bottom: 76px;
  }
`

export const Hero: ComponentType = () => (
  <Wrapper>
    <Media at="xs">
      <HeroMobile />
    </Media>
    <Media greaterThan="xs">
      <HeroDesktop />
    </Media>
  </Wrapper>
)
