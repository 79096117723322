import { FiveStars } from 'components/FiveStars'
import { TestimonialModal } from 'components/TestimonialModal'
import { ComponentType, Fragment, useState } from 'react'
import Slider from 'react-slick'
import Link from 'next/link'
import { GoChevronRight } from 'react-icons/go'
import { Button, Col, Container, Row } from 'reactstrap'

interface Props {
  testimonials: any[]
}

export const TestimonialsMobile: ComponentType<Props> = ({ testimonials }) => {
  const [testimonialId, setTestimonialId] = useState<null>()

  return (
    <div className="py-55 bg-lily-white overflow-hidden">
      <Container className="pb-15">
        <Row>
          <Col>
            <h3 className="mb-0 font-size-21 font-weight-bold text-center">Conoce la opinión de nuestros clientes</h3>
          </Col>
        </Row>
      </Container>
      <Slider infinite={false} dots>
        {testimonials.map((testimonial: any) => (
          <Fragment key={testimonial.id}>
            <div className="py-30 px-30 position-relative">
              <FiveStars />
              <div className="pt-25 text-primary font-family-serif font-italic font-size-19 line-height-md">
                {testimonial.title}
              </div>
              <div className="pt-50 d-flex justify-content-between">
                <div>
                  <h6 className="font-size-15 text-regal-blue font-weight-bold">{testimonial.quoted_by}</h6>
                  <h6 className="font-size-15 text-regal-blue">{testimonial.quoted_at}</h6>
                </div>
                <div>
                  <Button
                    color="link"
                    className="p-0 border-0 inversed-link-trout stretched-link"
                    onClick={() => setTestimonialId(testimonial.id)}
                  >
                    Leer completo
                  </Button>
                </div>
              </div>
            </div>
            <TestimonialModal
              testimonial={testimonial}
              open={testimonialId === testimonial.id}
              onToggle={() => setTestimonialId(null)}
            />
          </Fragment>
        ))}
      </Slider>
      <Row className="pt-65">
        <Col className="text-center">
          <Link href="/acerca/testimoniales">
            <a title="Conoce más testimoniales" className="font-weight-500">
              Conoce más testimoniales <GoChevronRight />
            </a>
          </Link>
        </Col>
      </Row>
    </div>
  )
}
