import { useBlogPostsQuery } from 'hooks/useBlogPostsQuery'
import { useRouter } from 'next/router'
import { ComponentType } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { BlogPostCard } from './BlogPostCard'

export const RelatedPosts: ComponentType = () => {
  const router = useRouter()

  const { data } = useBlogPostsQuery({
    variables: {
      query: {
        limit: 3,
        filter: router.query.postSlug ? `slug:-${router.query.postSlug}+tag:-[testimonios,archivo]` : undefined,
      },
    },
  })

  if (data) {
    const { posts } = data.blogPosts

    return (
      <Container>
        <Row>
          <Col>
            <h3 className="mb-15 font-family-serif font-size-22 font-weight-bold">Más artículos del blog</h3>
          </Col>
        </Row>
        <Row className="mb-n40">
          {posts.map((post: any) => (
            <Col key={post.id} lg={4} className="py-40">
              <BlogPostCard post={post} />
            </Col>
          ))}
        </Row>
      </Container>
    )
  }

  return null
}
