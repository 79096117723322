import { LatestBlogPosts } from 'components/LatestBlogPosts'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

const Wrapper = styled.div`

  ${up('sm')} {
    padding-bottom: 60px;
  }
`

export const Blog = () => (
  <Wrapper>
    <LatestBlogPosts />
  </Wrapper>
)
