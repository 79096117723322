import { useBlogPostsQuery } from 'hooks/useBlogPostsQuery'
import { Media } from 'media'
import { ComponentType } from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { TestimonialsDesktop } from './testimonials-desktop'
import { TestimonialsMobile } from './testimonials-mobile'

const Wrapper = styled.div`
  padding-bottom: 64px;

  ${up('sm')} {
    padding-bottom: 40px;
  }
`

export const Testimonials: ComponentType = () => {
  const { data } = useBlogPostsQuery({
    variables: {
      query: { limit: 3, filter: 'tag:testimonios' },
    },
  })

  const testimonials = data?.blogPosts.posts ?? []

  return (
    <Wrapper>
      <Media at="xs">
        <TestimonialsMobile testimonials={testimonials} />
      </Media>
      <Media greaterThan="xs">
        <TestimonialsDesktop testimonials={testimonials} />
      </Media>
    </Wrapper>
  )
}
