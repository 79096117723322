import { PropertyCard } from 'components/Property'
import { usePropertiesQuery } from 'hooks/usePropertiesQuery'
import Link from 'next/link'
import { ComponentType, useState } from 'react'
import { GoChevronRight } from 'react-icons/go'
import { FormattedMessage } from 'react-intl'
import { Button, Col, Container, Row } from 'reactstrap'

const LIMIT = 9

interface Props {
  categories: any[]
}

export const CategoriesDesktop: ComponentType<Props> = ({ categories }) => {
  const [activeUuid, setActiveUuid] = useState(categories[0]?.uuid)

  const activeCategory = categories.find(({ uuid }) => uuid === activeUuid)

  const { data } = usePropertiesQuery({
    variables: { query: { category: activeUuid, limit: LIMIT } },
  })

  return (
    <Container>
      <Row>
        <Col>
          <h6 className="font-size-14 font-weight-bold text-uppercase text-primary mb-15">
            <FormattedMessage id="home.categories.head.suptitle" />
          </h6>
          <h3 className="mb-10 font-size-26 font-family-serif font-weight-bold">
            <FormattedMessage id="home.categories.head.title" />
          </h3>
          <p className="mb-0 font-size-17">
            <FormattedMessage id="home.categories.head.subtitle" />
          </p>
        </Col>
      </Row>
      <Row className="pt-45">
        <Col>
          {categories.map((category) => (
            <Button
              key={category.uuid}
              color="tab"
              onClick={() => setActiveUuid(category.uuid)}
              active={category.uuid === activeCategory.uuid}
            >
              {category.pluralName}
            </Button>
          ))}
        </Col>
      </Row>
      <Row xs="1" sm="3" md="4" lg="5">
        {data?.properties.data.map((property: any) => (
          <div key={property.uuid} className="py-24 px-10">
            <PropertyCard property={property} />
          </div>
        ))}
        {data?.properties.total > LIMIT && (
          <div className="py-24 px-10">
            <Link href="/explorar/categoria/[categorySlug]" as={`/explorar/categoria/${activeCategory.slug}`} passHref>
              <Button
                tag="a"
                color="primary"
                className="d-flex align-items-center justify-content-center h-160px"
                outline
                block
              >
                <FormattedMessage id="viewmore" values={{ name: activeCategory.pluralName }} />
                <GoChevronRight />
              </Button>
            </Link>
          </div>
        )}
      </Row>
    </Container>
  )
}
