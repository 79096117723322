import { PropertyCard } from 'components/Property'
import { usePropertiesQuery } from 'hooks/usePropertiesQuery'
import Link from 'next/link'
import { ComponentType, useState } from 'react'
import { GoChevronRight } from 'react-icons/go'
import { FormattedMessage } from 'react-intl'
import { Button, Col, Container, Row } from 'reactstrap'

const LIMIT = 5

interface Props {
  categories: any[]
}

export const CategoriesMobile: ComponentType<Props> = ({ categories }) => {
  const [activeUuid, setActiveUuid] = useState(categories[0]?.uuid)

  const activeCategory = categories.find(({ uuid }) => uuid === activeUuid)

  const { data } = usePropertiesQuery({
    variables: { query: { category: activeUuid, limit: LIMIT } },
  })

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h6 className="mb-12 font-size-14 font-weight-bold text-uppercase text-primary">
              <FormattedMessage id="home.categories.head.suptitle" />
            </h6>
            <h3 className="mb-8 font-size-23 font-family-serif font-weight-bold">
              <FormattedMessage id="home.categories.head.title" />
            </h3>
            <p className="mb-0">
              <FormattedMessage id="home.categories.head.subtitle" />
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="pb-20 scroller overflow-auto">
        <Row>
          <Col>
            <div className="d-flex flex-nowrap">
              {categories.map((category) => (
                <Button
                  key={category.uuid}
                  color="tab"
                  active={category.uuid === activeCategory.uuid}
                  className="text-nowrap"
                  onClick={() => setActiveUuid(category.uuid)}
                >
                  {category.pluralName}
                </Button>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="px-30">
        <Row xs="1" sm="3" md="4" lg="5">
          {data?.properties.data.map((property: any) => (
            <div key={property.uuid} className="py-10">
              <PropertyCard property={property} />
            </div>
          ))}
          {data?.properties.total > LIMIT && (
            <Link
              href="/explorar/categoria/[categorySlug]"
              as={`/explorar/categoria/${activeCategory.slug}`}
              passHref
            >
              <Button
                tag="a"
                color="primary"
                className="py-33 text-center font-size-14 font-weight-bold"
                outline
                block
              >
                <FormattedMessage id="viewmore" values={{ name: activeCategory.pluralName }} />
                <GoChevronRight />
              </Button>
            </Link>
          )}
        </Row>
      </Container>
    </>
  )
}
