import { CoverBackground } from 'components/CoverBackground'
import Link from 'next/link'
import { ComponentType } from 'react'
import { FormattedDate } from 'react-intl'
import { Button, Container } from 'reactstrap'
import { Media } from 'media'

const HeroDesktop: ComponentType<{ post: any }> = ({ post }) => (
  <div className="px-20">
    <CoverBackground url={post.feature_image} className="pt-320 rounded-lg bg-porcelain">
      <div className="py-40 bg-blue-zodiac-95 rounded-lg-bottom">
        <Container>
          <div className="five-row justify-content-center">
            <div className="five-col-3">
              <h1 className="mb-40 text-white font-family-open-sans font-weight-bold font-size-48 text-shadow">
                {post.title}
              </h1>
              <h2 className="mb-0 font-family-serif font-size-22 text-anakiwa line-height-md">{post.excerpt}</h2>
              <div className="pt-40 d-flex align-items-center justify-content-between">
                {post.primary_tag && (
                  <Link
                    href={{
                      pathname: '/blog/categoria/[tagSlug]',
                      query: { tagSlug: post.primary_tag.slug },
                    }}
                    as={`/blog/categoria/${post.primary_tag.slug}`}
                    passHref
                  >
                    <Button
                      tag="a"
                      color="white"
                      className="px-20 rounded-pill font-size-14 text-uppercase font-weight-bold"
                    >
                      {post.primary_tag.name}
                    </Button>
                  </Link>
                )}
                <h6 className="text-white font-size-14 text-uppercase font-weight-bold">
                  <FormattedDate value={post.created_at} day="numeric" month="long" year="numeric" />
                </h6>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </CoverBackground>
  </div>
)

const HeroMobile: ComponentType<{ post: any }> = ({ post }) => (
  <div className="px-20">
    <CoverBackground url={post.feature_image} className="rounded-lg bg-porcelain">
      <div className="py-30 bg-blue-zodiac-95 rounded-lg-bottom">
        <Container>
          <div className="five-row justify-content-center">
            <div className="five-col-5">
              <h1 className="mb-20 text-white font-family-open-sans font-weight-bold font-size-26 text-shadow text-center">
                {post.title}
              </h1>
              <h2 className="mb-0 font-family-serif font-size-18 text-anakiwa line-height-md">{post.excerpt}</h2>
              <div className="pt-40 d-flex align-items-center justify-content-between">
                {post.primary_tag && (
                  <Link
                    href={{
                      pathname: '/blog/categoria/[tagSlug]',
                      query: { tagSlug: post.primary_tag.slug },
                    }}
                    as={`/blog/categoria/${post.primary_tag.slug}`}
                    passHref
                  >
                    <Button
                      tag="a"
                      color="white"
                      className="px-20 rounded-pill font-size-14 text-uppercase font-weight-bold"
                    >
                      {post.primary_tag.name}
                    </Button>
                  </Link>
                )}
              </div>
              <br />
              <h6 className="text-white font-size-14 text-uppercase font-weight-bold">
                <FormattedDate value={post.created_at} day="numeric" month="long" year="numeric" />
              </h6>
            </div>
          </div>
        </Container>
      </div>
    </CoverBackground>
  </div>
)

const Content: ComponentType<{ post: any }> = ({ post }) => (
  <div className="pt-50 pb-120">
    <Container>
      <div className="five-row justify-content-center">
        <div className="five-col-5">
          <div dangerouslySetInnerHTML={{ __html: post.html }} className="ghost-content" />
        </div>
      </div>
    </Container>
  </div>
)

export const BlogPost: ComponentType<{ post: any }> = ({ post }) => (
  <>
    <Media at="xs">
      <HeroMobile post={post} />
    </Media>
    <Media greaterThan="xs">
      <HeroDesktop post={post} />
    </Media>
    <Content post={post} />
  </>
)
