import { useZonesQuery } from 'hooks/useZonesQuery'
import { ComponentType } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Col, Container, Row } from 'reactstrap'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { LinkRow } from 'views/common/link-row'

const Wrapper = styled.div`
  padding-bottom: 64px;

  ${up('sm')} {
    padding-bottom: 54px;
  }
`

const Intro = styled.div`
  padding-bottom: 24px;
`

const Suptitle = styled.h6`
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #0078c9;

  ${up('sm')} {
    margin-bottom: 14px;
  }
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 23px;

  ${up('sm')} {
    font-size: 26px;
  }
`

const Subtitle = styled.p`
  margin: 0;
  color: #22262a;
  font-size: 16px;

  ${up('sm')} {
    font-size: 17px;
  }
`

export const Zones: ComponentType = () => {
  const { formatMessage } = useIntl()
  const { data } = useZonesQuery()
  const zones = data?.zones.data ?? []

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col>
            <Intro>
              <Suptitle>
                <FormattedMessage id="home.zones.suptitle" />
              </Suptitle>
              <Title>
                <FormattedMessage id="home.zones.title" />
              </Title>
              <Subtitle>
                <FormattedMessage id="home.zones.subtitle" />
              </Subtitle>
            </Intro>
          </Col>
        </Row>
        <Row>
          {zones.map((zone: any) => (
            <Col md={6} key={zone.uuid}>
              <LinkRow
                href="/explorar/zona/[zoneSlug]"
                as={`/explorar/zona/${zone.slug}`}
                title={zone.name}
                subtitle={formatMessage({ id: 'properties.count' }, { count: zone.properties })}
                image={zone.cover?.thumbnailUrl}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Wrapper>
  )
}
