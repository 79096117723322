import { useCategoriesQuery } from 'hooks/useCategoriesQuery'
import { Media } from 'media'
import { ComponentType } from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { CategoriesDesktop } from './categories-desktop'
import { CategoriesMobile } from './categories-mobile'

const Wrapper = styled.div`
  padding-bottom: 46px;

  ${up('sm')} {
    padding-bottom: 50px;
  }
`

export const Categories: ComponentType = () => {
  const { data } = useCategoriesQuery()
  const categories = data?.categories.data ?? []

  if (categories && categories.length) {
    return (
      <Wrapper>
        <Media at="xs">
          <CategoriesMobile categories={categories} />
        </Media>
        <Media greaterThan="xs">
          <CategoriesDesktop categories={categories} />
        </Media>
      </Wrapper>
    )
  }

  return null
}
