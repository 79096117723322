import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { NextPage } from 'next'
import { NextSeo } from 'next-seo'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Home } from 'views/home'

const HomePage: NextPage = () => {
  const { formatMessage } = useIntl()

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  return (
    <>
      <NextSeo
        title={formatMessage({ id: 'home.meta.title' })}
        description={formatMessage({ id: 'home.meta.description' })}
      />
      <Header bordered={false} />
      <Home />
      <Footer />
    </>
  )
}

export default HomePage
