import { ComponentType } from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

export const Certificate: ComponentType = () => (
    <div className="certificate-desktop">
        <img alt="Logo certificado A.M.P.I" src="/images/certificado.png" height="40px" className="mr-2" />
        Contamos con asesores certificados por AMPI Mérida
    </div>
)
