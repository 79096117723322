import { Media } from 'media'
import { ComponentType } from 'react'
import { Blog } from './blog'
import { Categories } from './categories'
import { Footer } from './footer'
import { Hero } from './hero'
import { Testimonials } from './testimonials'
import { Zones } from './zones'
import { Certificate } from 'components/Certificate'

export const Home: ComponentType = () => (
  <>
    <Hero />
    <Media greaterThan="xs">
      <Certificate />
    </Media>
    <Categories />
    <Zones />
    <Blog />
    <Testimonials />
    <Footer />
  </>
)
