import { CoverBackground } from 'components/CoverBackground'
import Link, { LinkProps } from 'next/link'
import { ComponentType } from 'react'
import { GoChevronRight } from 'react-icons/go'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 16px 18px 0;
  position: relative;
  border-bottom: 1px solid #e5e8ec;

  :hover {
    border-bottom-color: #0078c9;

    .icon {
      fill: #0078c9;
    }
  }

  ${up('sm')} {
    padding: 20px 47px 20px 0;
  }
`

const Image = styled(CoverBackground)`
  width: 78px;
  height: 56px;
  margin-right: 16px;
  background-color: #f7f8f9;
  border-radius: 3px;

  ${up('sm')} {
    width: 123px;
    height: 88px;
    margin-right: 24px;
  }
`

const Content = styled.div`
  flex: 1 1 auto;
`

const Anchor = styled.a`
  display: block;
  font-weight: bold;
  color: #22262a;

  :hover {
    text-decoration: none;
    color: #0078c9;
  }

  ${up('sm')} {
    font-size: 17px;
  }
`

const Subtitle = styled.span`
  color: #757d88;
  font-size: 14px;

  ${up('sm')} {
    font-size: 16px;
  }
`

interface Props extends LinkProps {
  title: string
  subtitle: string
  image?: string
}

export const LinkRow: ComponentType<Props> = ({ title, subtitle, image, ...props }) => (
  <Wrapper>
    <Image url={image} />
    <Content>
      <Link {...props} passHref>
        <Anchor title={title} className="stretched-link">
          {title}
        </Anchor>
      </Link>
      <Subtitle>{subtitle}</Subtitle>
    </Content>
    <GoChevronRight color="#C5CCD5" className="icon" />
  </Wrapper>
)
