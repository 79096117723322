import { TestimonialModal } from 'components/TestimonialModal'
import { TestimonialCard } from 'components/Testimonials'
import Link from 'next/link'
import { ComponentType, useState } from 'react'
import { GoChevronRight } from 'react-icons/go'
import { Col, Container, Row } from 'reactstrap'

interface Props {
  testimonials: any[]
}

export const TestimonialsDesktop: ComponentType<Props> = ({ testimonials }) => {
  const [testimonialId, setTestimonialId] = useState<null>()

  return (
    <div className="px-20">
      <div className="bg-lily-white rounded-lg py-32">
        <Container>
          <Row>
            <Col>
              <h3 className="font-size-26 font-weight-bold text-regal-blue text-center">
                Conoce la opinión de nuestros clientes
              </h3>
            </Col>
          </Row>
          <Row className="pt-16">
            {testimonials.map((testimonial: any, index: number, arr: any[]) => {
              const prev = arr[index - 1]
              const next = arr[index + 1]

              return (
                <Col sm={4} key={testimonial.id} lg={4} className="py-15">
                  <TestimonialCard testimonial={testimonial} onClick={() => setTestimonialId(testimonial.id)} />
                  {testimonialId === testimonial.id && (
                    <TestimonialModal
                      testimonial={testimonial}
                      onPrev={prev ? () => setTestimonialId(prev.id) : undefined}
                      onNext={next ? () => setTestimonialId(next.id) : undefined}
                      onToggle={() => setTestimonialId(null)}
                      open
                    />
                  )}
                </Col>
              )
            })}
          </Row>
          <Row className="pt-24">
            <Col className="text-center">
              <Link href="/acerca/testimoniales">
                <a title="Conoce más testimoniales" className="font-weight-500">
                  Conoce más testimoniales <GoChevronRight />
                </a>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
