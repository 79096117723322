import { SeoFooter } from 'components/SeoFooter'
import { ComponentType } from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

const Wrapper = styled.div`
  ${up('sm')} {
    padding-bottom: 24px;
  }
`

export const Footer: ComponentType = () => (
  <Wrapper>
    <SeoFooter />
  </Wrapper>
)
